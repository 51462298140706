import { ArrowNarrowRightIcon } from "@heroicons/react/outline";
import { Document } from "@prismicio/client/types/documents";
import { ButtonLink } from "components/Button";
import i18n from "i18next";
import readTime from "lib/readTime";
import Image from "next/image";
import Link from "next/link";
import React from "react";

export default function Articles({ articles }: { articles: Document[] }) {
  return (
    <div className="space-y-8">
      <h2 className="text-3xl font-bold text-gray-900">Recent articles.</h2>
      <div className="grid gap-8 lg:grid-cols-3 lg:gap-12 md:grid-cols-2 md:gap-12">
        {articles.map(article => (
          <div key={article.id}>
            <Link href={`/blog/article/${article.uid}`}>
              <a href={`/blog/article/${article.uid}`} title={article.data.title[0].text}>
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden transform hover:-rotate-1 hover:scale-105 hover:shadow-xl transition-all duration-500 ease">
                  <div className="flex-shrink-0">
                    <div className="relative h-48 w-full">
                      <Image
                        src={article.data.cover_image.url}
                        alt={article.data.cover_image.alt || article.data.title[0].text}
                        layout="fill"
                        objectFit="cover"
                      />
                    </div>
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <div className="mt-2">
                        <p className="text-xl font-semibold text-gray-900">{article.data.title[0].text}</p>
                        <p className="mt-3 text-base text-gray-500 line-clamp-2">{article.data.summary}</p>
                      </div>
                    </div>
                    <div className="mt-6 flex items-center">
                      <div className="flex-shrink-0">
                        <span className="sr-only">{article.data.author.data.name}</span>
                        <div className="relative h-10 w-10">
                          <Image
                            src={article.data.author.data.photo.url}
                            alt={article.data.author.data.name}
                            layout="fill"
                            objectFit="cover"
                            className="rounded-full"
                          />
                        </div>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">{article.data.author.data.name}</p>
                        <div className="flex space-x-1 text-sm text-gray-500">
                          {article.first_publication_date && (
                            <>
                              <time dateTime={article.first_publication_date}>
                                {new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }).format(new Date(article.first_publication_date))}
                              </time>
                              <span aria-hidden="true">&middot;</span>
                            </>
                          )}
                          <span>{readTime(article)} min read</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </Link>
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        <div>
          <Link href="/blog" passHref>
            <ButtonLink
              href="/blog"
              className="inline-flex items-center"
              title={i18n.t("Visit my blog")}
              icon={<ArrowNarrowRightIcon className="ml-2 -mr-0.5 h-6 w-6" aria-hidden="true" />}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
