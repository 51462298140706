import classNames from "classnames";
import Link from "next/link";
import React from "react";

type NavigationLinkProps = {
  href: string;
  label: string;
  isActive: boolean;
};

export default function NavigationLink({ href, label, isActive }: NavigationLinkProps) {
  return (
    <Link href={href}>
      <a
        href={href}
        className={classNames(
          "py-1 px-2 border-2 border-transparent hover:border-gray-600 rounded-md text-lg font-medium hover:text-gray-700  transition-all duration-300 ease",
          {
            "border-gray-600": isActive,
          },
        )}
      >
        {label}.
      </a>
    </Link>
  );
}
