import { Disclosure, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import NavigationLink from "components/NavigationLink";
import i18n from "i18n";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { Fragment } from "react";

export default function Navigation({ show = true }: { show?: boolean }) {
  const router = useRouter();
  return (
    <Disclosure as="div">
      {({ open }) => (
        <>
          <div
            className={classNames(
              "fixed top-0 flex flex-col justify-center items-center z-10 shadow-sm w-full min-h-[5rem] bg-white bg-opacity-50 backdrop-filter backdrop-blur-md transition-all duration-300 ease",
              {
                "-top-16 opacity-0": !show,
                "top-0 opacity-100": show || open,
              },
            )}
          >
            <div className="relative h-20 flex justify-end md:justify-center w-full max-w-lg sm:max-w-3xl lg:max-w-7xl">
              <Link href="/">
                <a
                  href="/"
                  className="absolute top-[50%] left-0 transform -translate-y-2/4"
                  aria-label={i18n.t("Back to home")}
                >
                  <svg
                    className="w-10 h-10"
                    viewBox="0 0 1000 1000"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M776.097 470.672C827.599 426.323 853.35 366.238 853.35 288.984C853.35 104.435 717.442 0 479.96 0H108V444.921H468.515C581.534 444.921 621.591 376.252 621.591 313.305C621.591 228.898 565.797 180.258 468.515 180.258H338.329V384.836H168.086V60.0859H479.96C681.677 60.0859 793.265 141.631 793.265 288.984C793.265 361.946 764.652 413.448 707.428 446.352L691.691 454.936V496.424L710.289 505.007C790.403 539.342 831.891 599.428 831.891 683.834C831.891 851.216 721.734 939.914 515.725 939.914H168.086V602.289H338.329V818.312H502.85C598.701 818.312 658.787 762.518 658.787 673.82C658.787 590.844 600.132 542.203 502.85 542.203H108V1000H515.725C640.189 1000 736.04 969.957 800.418 911.302C860.504 856.938 891.977 778.255 891.977 683.834C891.977 590.844 851.92 517.883 776.097 470.672ZM399.845 240.343H468.515C551.491 240.343 561.505 278.97 561.505 313.305C561.505 360.515 530.031 384.836 468.515 384.836H399.845V240.343ZM399.845 602.289H502.85C598.701 602.289 598.701 656.652 598.701 673.82C598.701 729.614 565.797 758.226 502.85 758.226H399.845V602.289Z"
                      fill="black"
                    />
                  </svg>
                </a>
              </Link>
              <Disclosure.Button className="md:hidden block text-gray-900 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                <span className="sr-only">{i18n.t("Open main menu")}</span>
                {open ? (
                  <XIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
              <div className="md:flex hidden justify-center items-center space-x-16">
                <NavigationLink
                  href="/blog"
                  label={i18n.t("Blog")}
                  isActive={router.pathname.startsWith("/blog")}
                />
                <NavigationLink
                  href="/about"
                  label={i18n.t("About me")}
                  isActive={router.pathname.startsWith("/about")}
                />
                <NavigationLink
                  href="/contact"
                  label={i18n.t("Contact")}
                  isActive={router.pathname.startsWith("/contact")}
                />
              </div>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <nav className="md:hidden flex flex-col">
                <Disclosure.Panel className="relative flex flex-col justify-center items-center max-w-lg sm:max-w-3xl lg:max-w-7xl w-full h-screen space-y-4">
                  <NavigationLink
                    href="/blog"
                    label={i18n.t("Blog")}
                    isActive={router.pathname.startsWith("/blog")}
                  />
                  <NavigationLink
                    href="/about"
                    label={i18n.t("About me")}
                    isActive={router.pathname.startsWith("/about")}
                  />
                  <NavigationLink
                    href="/contact"
                    label={i18n.t("Contact")}
                    isActive={router.pathname.startsWith("/contact")}
                  />
                </Disclosure.Panel>
              </nav>
            </Transition>
          </div>
        </>
      )}
    </Disclosure>
  );
}
