import classNames from "classnames";
import { DetailedHTMLProps, forwardRef, HTMLAttributes, ReactNode } from "react";

type ButtonProps = {
  icon?: ReactNode;
  title: ReactNode | JSX.Element;
};

export function Button({ ...props }) {
  return (
    <button
      className={classNames(
        "block w-full rounded-md px-5 py-3 bg-gradient-to-r from-[#FF512F] to-[#FFC837] text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600 sm:px-10 hover:shadow-lg transition-all duration-300 ease",
        props.className,
      )}
    >
      {props.children}
    </button>
  );
}

type ButtonLinkProps = {
  href: string;
} & Omit<ButtonProps, "isLoading">;

export const ButtonLink = forwardRef<
  HTMLAnchorElement,
  ButtonLinkProps & DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
>(({ icon = null, title, onClick, href, className, ...props }, ref) => {
  return (
    <a
      href={href}
      ref={ref}
      onClick={onClick}
      className={classNames(
        "block w-full rounded-md px-5 py-3 bg-gradient-to-r from-[#FF512F] to-[#FFC837] text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600 sm:px-10 hover:shadow-lg transition-all duration-300 ease",
        className,
      )}
    >
      {title}
      {icon}
    </a>
  );
});
