import { ArrowNarrowDownIcon } from "@heroicons/react/outline";
import { Document } from "@prismicio/client/types/documents";
import classNames from "classnames";
import { WideContainer } from "components/Container";
import Footer from "components/Footer";
import Main from "components/Main";
import Navigation from "components/Navigation";
import { useViewportScroll } from "framer-motion";
import i18n from "i18n";
import { GetStaticPropsContext } from "next";
import Head from "next/head";
import Articles from "pages/index/components/Articles";
import { client } from "prismic-configuration";
import Prismic from "prismic-javascript";
import Newsletter from "components/Newsletter";
import React, { useState } from "react";

export async function getStaticProps({ preview = false }: GetStaticPropsContext) {
  const { results: articles } = await client.query(Prismic.Predicates.at("document.type", "article"), {
    lang: "en-us",
    orderings: "[my.article.date desc]",
    fetchLinks: ["author.name", "author.photo"],
  });

  articles.slice(0, 2);

  return {
    props: { articles, preview },
  };
}

export default function Home({ articles }: { articles: Document[] }) {
  const { scrollYProgress } = useViewportScroll();
  const [isScrolled, setIsScrolled] = useState(false);
  const [showNavigation, setShowNavigation] = useState(false);

  scrollYProgress.onChange(x => {
    setIsScrolled(x > 0.1);
    setShowNavigation(x > 0.175);
  });

  return (
    <>
      <Head>
        <title>{i18n.t("Bjarn Bronsveld - Full Stack Developer")}</title>
        <meta property="og:title" content="Bjarn Bronsveld - Full Stack Developer" key="og_title" />
        <meta name="twitter:title" content="Bjarn Bronsveld - Full Stack Developer" key="twitter_title" />
      </Head>

      <Navigation show={showNavigation} />

      <div
        className={classNames(
          "fixed inset-0 flex h-screen w-full md:p-8 p-4 bg-gradient-to-r from-[#FF512F] to-[#FFC837] transition-padding duration-300 ease",
          {
            "md:p-0 p-0": isScrolled,
          },
        )}
      >
        <div
          className={classNames("w-full rounded-md shadow-xl bg-white transition-all duration-300 ease", {
            "rounded-none shadow-none": isScrolled,
          })}
        >
          <div
            className={classNames(
              "flex h-full w-full justify-center items-end lg:pb-24 pb-12 transition-all duration-300 ease select-none",
              {
                "opacity-0 lg:pb-64 pb-48": isScrolled,
              },
            )}
          >
            <ArrowNarrowDownIcon className="animate-bounce h-12 w-12 text-gray-900" />
          </div>
        </div>
      </div>
      <div className="relative flex flex-col w-full overflow-hidden">
        <div
          className={classNames(
            "h-screen flex flex-col justify-center items-center p-4 space-y-4 transform-gpu transition-transform duration-300 ease",
            {
              "-translate-y-1/3-screen": isScrolled,
            },
          )}
        >
          <h1 className="xl:text-8xl md:text-6xl text-4xl font-bold text-gray-900">
            {i18n.t("Hi, I am")}{" "}
            <span className="bg-gradient-to-r from-[#FF512F] to-[#FFC837] text-transparent bg-clip-text">
              Bjarn
            </span>
            .
          </h1>
          <p className="md:text-lg text-md text-gray-700 font-medium text-center">
            {i18n.t("Full stack web & mobile app developer.")}
          </p>
        </div>
        <div
          className={classNames(
            "flex w-full justify-center items-center flex-col transform-gpu transition-all duration-300 ease -mt-1/2-screen",
            {
              "opacity-0 translate-y-1/2-screen": !isScrolled,
              "opacity-100 translate-y-0": isScrolled,
            },
          )}
        >
          <Main>
            <WideContainer>
              <div className="space-y-32">
                <Articles articles={articles} />
                <Newsletter />
              </div>
            </WideContainer>
          </Main>
          <WideContainer>
            <div className="mt-32">
              <Footer />
            </div>
          </WideContainer>
        </div>
      </div>
    </>
  );
}
